<template>
  <div class="min-h-screen bg-gray-100 flex items-center justify-center">
    <div class="container max-w-screen-lg mx-auto">
      <div>
        <div class="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
          <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
            <div class="text-gray-600">
              <p class="font-medium text-lg ">Producto : {{route.query.Nombre}}</p>
              <div class="flex items-center justify-center p-3">
                <img data-v-353dbe55="" :src="'https://int.pharmasan.net/api/config-ov/articulos/get-image/'+_catalogoStore.data.PicturName"  class="w-6rem shadow-2 border-round">
              </div>
            </div>
            <form @submit="submit" class="lg:col-span-2">
                <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">

                  <div class="md:col-span-5" v-if="!route.params.id">
                    <label for="searchArticulo" class="w-full">Buscar Articulo</label>
                    <AutoComplete v-model="searchArticulo"
                                  :suggestions="suggestions"
                                  optionLabel="ItemCode"
                                  optionValue="ItemCode"
                                  @item-select="selectArt"
                                  @complete="search"
                                  size="small"
                                  class="w-full"
                                  input-class="w-full"
                                  auto-option-focus="true"
                    >
                      <template #option="{option}">
                        <div class="flex align-options-center">
                          <div> {{option.ItemCode}} - {{ option.ItemName }}</div>
                        </div>
                      </template>
                    </AutoComplete>
                  </div>

                  <div class="md:col-span-2" v-if="route.params.id">
                    <label for="ArticuloId">ID</label>
                    <InputText id="ArticuloId" v-model="_catalogoStore.data.ArticuloId" disabled class="w-full"  aria-describedby="text-error" />
                    <span class="p-error text-red-800" id="text-error">{{ listErrors['ArticuloId'] || '&nbsp;' }}</span>
                  </div>

                  <div class="md:col-span-3" v-if="route.params.id">
                    <label for="ItemCode">Codigo</label>
                    <InputText id="ItemCode" v-model="_catalogoStore.data.ItemCode" disabled class="w-full"  aria-describedby="text-error" />
                    <span class="p-error" id="text-error">{{ listErrors['ItemCode'] || '&nbsp;' }}</span>
                  </div>

                  <div class="md:col-span-5">
                    <label for="ItemName">Descripción</label>
                    <InputText id="ItemName" v-model="_catalogoStore.data.ItemName" disabled class="w-full"  aria-describedby="text-error" />
                    <span class="p-error" id="text-error">{{ listErrors['ItemName'] || '&nbsp;' }}</span>
                  </div>

                  <div class="md:col-span-3">
                    <label for="CodeBars">Codigo de barras</label>
                    <InputText id="CodeBars" v-model="_catalogoStore.data.CodeBars" disabled class="w-full"  aria-describedby="text-error" />
                    <span class="p-error" id="text-error">{{ listErrors['CodeBars'] || '&nbsp;' }}</span>
                  </div>

                  <div class="md:col-span-2">
                    <label for="SWW">CUM</label>
                    <InputText id="SWW" v-model="_catalogoStore.data.SWW" disabled class="w-full"  aria-describedby="text-error" />
                    <span class="p-error" id="text-error">{{ listErrors['SWW'] || '&nbsp;' }}</span>
                  </div>

                  <div class="md:col-span-1">
                    <label for="VlrUnitario">Precio</label>
                    <InputNumber id="VlrUnitario" v-model="_catalogoStore.data.VlrUnitario"  class="w-full"  aria-describedby="text-error" />
                    <span class="p-error" id="text-error">{{ listErrors['VlrUnitario'] || '&nbsp;' }}</span>
                  </div>

                  <div class="md:col-span-5 text-right">
                    <div class="inline-flex items-end gap-4">
                      <Button type="submit" label="Validar" />
                      <Button type="button" severity="danger" label="Cancelar" @click="onCancel" />
                    </div>
                  </div>

                </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <Toast />
  </div>
</template>
<script setup>
  import { ref, onMounted } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import * as Yup from 'yup'
  import { useField, useForm } from 'vee-validate'
  import { catalogoStore } from '../store/catalogo.store'
  import Swal from 'sweetalert2'
  import { useToast } from 'primevue/usetoast'
  const _catalogoStore = catalogoStore()
  const router = useRouter()
  const route = useRoute()
  const toast = useToast()
  onMounted(() => {
    _catalogoStore.data.ProveedorId = route.query.ProveedorId
    if (route.params.id) {
      if (_catalogoStore.data.ArticuloId === null) {
        router.go(-1)
      }
    }
  })
  // Define tu esquema de validación
  const schema = Yup.object().shape({
    ProveedorId: route.params.id ? Yup.number().required().label(' ') : Yup.number().nullable().label(' '),
    ArticuloId: route.params.id ? Yup.number().required().label(' ') : Yup.number().nullable().label(' '),
    VlrUnitario: Yup.number().required().label(' ')
  })

  // Crea tu formulario
  const { validate, resetForm } = useForm({
    validationSchema: schema,
    initialValues: _catalogoStore.data
  })

  const listErrors = ref({})

  // Crea tus campos
  // eslint-disable-next-line no-unused-vars
  const fields = Object.keys(_catalogoStore.data).reduce((acc, key) => {
    acc[key] = useField(key)
    return acc
  }, {})

  // Maneja el envío del formulario
  const submit = async (e) => {
    e.preventDefault()
    const { errors, valid } = await validate()
    listErrors.value = {
      ...errors,
      ...listErrors.value
    }
    // console.log('listErrors.value', listErrors.value)
    if (!valid) return
    Swal.fire({
      icon: 'warning',
      title: '¿Desea guardar los cambios?',
      text: 'Esta acción no se puede revertir',
      showCancelButton: true,
      confirmButtonText: 'SI',
      cancelButtonText: 'NO'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        _catalogoStore.putData().then(() => {
          toast.add({ severity: 'success', summary: 'Registro exitoso', life: 3000 })
          onCancel()
        })
      }
    })
  }

  const onCancel = () => {
    resetForm()
    _catalogoStore.resetData()
    router.go(-1)
  }

  const searchArticulo = ref(null)
  const suggestions = ref([])
  const search = ({ query }) => {
    if (query.length === 0) {
      suggestions.value = []
    } else {
      _catalogoStore.searchArticulo(query).then(({ data }) => {
        suggestions.value = data
      })
    }
  }
  const selectArt = (e) => {
    _catalogoStore.data.ArticuloId = e.value.Id
    _catalogoStore.data.VlrUnitario = 0
    _catalogoStore.data.ItemCode = e.value.ItemCode
    _catalogoStore.data.ItemName = e.value.ItemName
    _catalogoStore.data.CodeBars = e.value.CodeBars
    _catalogoStore.data.SWW = e.value.SWW
    _catalogoStore.data.PicturName = e.value.PicturName
  }
</script>

<style lang="scss" scoped>
.content {
  min-height: 95vh !important;
}
.min-h-screen {
  min-height: 84vh;
}
.p-inputtext.p-component {
  width: 100% !important;
}

</style>
