/* eslint-disable no-useless-return */
import { defineStore } from 'pinia'
import CatalogoServices from '../services/catalogo.services'
const _CatalogoServices = new CatalogoServices()
export const catalogoStore = defineStore('CatalogoStore', {
  state: () => {
    return {
      data: {
        Id: null,
        ProveedorId: null,
        ArticuloId: null,
        VlrUnitario: 0,
        ItemCode: null,
        ItemName: null,
        CodeBars: null,
        SWW: null,
        PicturName: null
      }
    }
  },
  getters: {
    _data: (state) => state.data
  },
  actions: {
      putData () {
        return new Promise((resolve, reject) => {
          _CatalogoServices.put({
            catalogos: [
              {
                Id: this.data.Id,
                ProveedorId: this.data.ProveedorId,
                ArticuloId: this.data.ArticuloId,
                VlrUnitario: this.data.VlrUnitario
              }
            ]
          }).then((response) => {
            resolve(response)
          }).catch((error) => {
            reject(error)
          })
        })
     },
      putXlsxData (payload) {
        return new Promise((resolve, reject) => {
        _CatalogoServices.put({
          catalogos: payload
        }).then(({ data }) => {
          resolve(data)
        }).catch((error) => {
          reject(error)
        })
      })
      },
      saveData () {
          return new Promise((resolve, reject) => {
            _CatalogoServices.post(
               [
                {
                  // Id: this.data.Id,
                  ProveedorId: this.data.ProveedorId,
                  ArticuloId: this.data.ArticuloId,
                  VlrUnitario: this.data.VlrUnitario
                }
              ]
            ).then((response) => {
              resolve(response)
            }).catch((error) => {
              reject(error)
            })
          })
       },
      saveXlsxData (payload) {
          return new Promise((resolve, reject) => {
            _CatalogoServices.post(
              payload
            ).then(({ data }) => {
              resolve(data)
            }).catch((error) => {
              reject(error)
            })
          })
      },
      async setData (payload) {
       this.data.Id = payload.Id
        this.data.ProveedorId = payload.ProveedorId
        this.data.ArticuloId = payload.ArticuloId
        this.data.VlrUnitario = payload.VlrUnitario
        this.data.ItemCode = payload.ItemCode
        this.data.ItemName = payload.ItemName
        this.data.CodeBars = payload.CodeBars
        this.data.SWW = payload.SWW
        this.data.PicturName = payload.PicturName
     },
      async resetData () {
       this.data.Id = null
       this.data.ProveedorId = null
       this.data.ArticuloId = null
       this.data.VlrUnitario = 0
       this.data.ItemCode = null
       this.data.ItemName = null
       this.data.CodeBars = null
       this.data.SWW = null
       this.data.PicturName = null
     },
      searchArticulo (payload) {
        return new Promise((resolve, reject) => {
          _CatalogoServices.searchArticulo(payload).then((response) => {
            resolve(response)
          }).catch((error) => {
            reject(error)
          })
        })
     },
      getArticulos () {
        return new Promise((resolve) => {
          _CatalogoServices.getArticulos().then(({ data }) => {
            resolve(data)
          })
        })
      }
  }
})
