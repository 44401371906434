import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_MXCOMPRAS + '/catalogos'
export default class CatalogoServices {
  post (data) {
    return http.post(`${baseUrl}`, data, {
      headers: {
        loading: true
      }
    })
  }

  put (data) {
    return http.put(`${baseUrl}`, data, {
      headers: {
        loading: true
      }
    })
  }

  searchArticulo (payload) {
    return http.get(`${baseUrl}/search-articulo`, {
      headers: {
        loading: true
      },
      params: {
        search: payload
      }
    })
  }

  getArticulos () {
    return http.get(`${baseUrl}/articulos`, {
      headers: {
        loading: true
      }
    })
  }
}
